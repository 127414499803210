<template>
  <v-container fill-height fluid class="pa-0">
    <v-container ref="chat-list" fluid class="pa-2 chat-list" v-bind:class="{ 'loading-messages': loadingMessages }">
      <chatMessage v-for="message in chatMessages" :message="message" :key="message.id" :mod="isModerator" @deleteMessage="deleteMessage" @banUser="banUser"/>
      <v-flex xs12></v-flex>
      <v-flex xs12 class="text-center pb-4 chat-info" v-if="showLoadMore && !loadingMessages" @click="getMoreMessages">Load more messages</v-flex>
    </v-container>
    <v-layout class="chat-text-area">
      <v-flex>
        <v-textarea class="chat-box pa-2" :placeholder="chatPlaceholder" solo v-model="message" @change="messageChanged" :disabled="!canChat || !chatConnected" flat no-resize :height="'100%'">
        </v-textarea>
      </v-flex>
      <v-flex class="send-button-container">
        <v-btn class="send-button" color="primary" tile :disabled="!canChat || message == '' || !chatConnected" @click="sendMessage">
          <v-icon>mdi-message</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { Api } from '../../js/api';
import Vue from 'vue';
import chatMessage from './chatMessage'
export default {
  name: 'Chat',
  props: [],
  components: {
    chatMessage
  },
  data: () => ({
    sending: false,
    chatLocked: true,
    noMoreMessages: false,
    loadingMessages: false,
    message: '',
    maxMessageLength: 200
  }),
  computed: {
    showLoadMore () {
      if (!this.noMoreMessages && this.chatMessages && this.chatMessages.length >= 2) {
        return true;
      }
      return false;
    },
    chatConnected () {
      return this.$store.getters.getChatConnected;
    },
    chatPlaceholder () {
      if (!this.chatConnected) {
        return 'Connecting to chat...';
      }
      return '';
    },
    chatMessages () {
      return this.$store.getters.getChatMessages;
    },
    canChat () {
      if (!this.sending && this.$store.getters.getIsLoggedIn && this.user && !this.user.chatBanned) {
        return true;
      }
      return false;
    },
    user () {
      return this.$store.getters.getUser;
    },
    chatList () {
      return this.$refs['chat-list'];
    },
    isModerator () {
      return this.$store.getters.hasKey('Moderation');
    }
  },
  methods: {
    messageChanged: function () {
      if ( this.message.length > this.maxMessageLength) {
        this.message =  this.message.slice(0, this.maxMessageLength);
      }
    },
    getMessages: function () {
      let scope = this;
      scope.$store.dispatch('getChatMessages');
    },
    getMoreMessages: function () {
      let scope = this;
      scope.loadingMessages = true;
      scope.$store.dispatch('getMoreChatMessages', {
        offset: this.chatMessages.length,
        amount: 15
      }).then(function (res) {
        Vue.nextTick(function () {
          scope.loadingMessages = false;
        })
        if (res.length == 0) {
          scope.noMoreMessages = true;
        }
      });
    },
    sendMessage: function () {
      let scope = this;

      scope.sending = true;

      scope.message = scope.message.slice(0, this.maxMessageLength);
      let data = scope.message;
      Api.post('Chat/message', data).then(function () {
        scope.sending = false;
        scope.message = '';
      })
    },
    deleteMessage: function (message) {
      let data = message.id;
      Api.post('Chat/delete', data).then(function () {
      })
    },
    banUser: function (message) {
      let data = message.id;
      Api.post('Chat/ban', data).then(function () {
      })
    }
  },
  mounted () {
    this.getMessages();
  }
}
</script>
<style lang="scss" scoped>
.chat-list {
  height: calc(100% - 96px);
  overflow-y: scroll;
  flex-direction: column-reverse;
  display: flex;
}

.chat-info {
  max-height: 32px;
  height: 32px;
}
.chat-text-area {
  height: 96px !important;
  border: 2px solid var(--v-primary-base);
}
.send-button-container {
  width: 48px;
  max-width: 48px;
}
.send-button {
  height: 100% !important;
  width: 100% !important;
  min-width: initial !important;
  padding: 0px !important;
}

.loading-messages {
  padding-left: 9px !important;
  margin-left: -1px !important;
}

// mobile
.disrupt-mobile .chat-list {
  height: calc(100% - 56px);
}
.disrupt-mobile .chat-text-area {
  height: 56px !important;
}
</style>
<style lang="scss">
.chat-text-area textarea,
.chat-text-area .v-input__control,
.chat-text-area .v-input__slot,
.chat-text-area .v-text-field__slot,
.chat-text-area .v-textarea {
  height: 100%;
  margin: 0px;
}
</style>
<style lang="scss">
.chat-box .v-input__slot {
  background-color: inherit !important;
}
</style>