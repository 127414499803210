<template>
  <v-hover v-slot="{ hover }" open-delay="100">
    <v-flex xs12 class="chat-message" v-if="!message.removed">
      <v-img class="chat-message-image" :src="image" :height="48" :width="48" :aspect-ratio="1"></v-img>
      <div class="chat-message-content">
        <span class="message-name">{{ message.username }}: </span>
        <span class="message-text">{{ message.text }}</span>
      </div>
      <div class="chat-message-actions" v-if="hover && mod">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="deleteMessage" icon :height="16" :width="16">
              <v-icon :size="16" color="red">
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Delete message</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="banUser" icon :height="16" :width="16">
              <v-icon :size="16" color="red">
                mdi-cancel
              </v-icon>
            </v-btn>
          </template>
          <span>Ban user</span>
        </v-tooltip>
      </div>
    </v-flex>
    <v-flex xs12 class="chat-message" v-else>
      <div class="deleted-chat-message-content">
        <span class="message-name">{{ message.text }}</span>
      </div>
    </v-flex>
  </v-hover>
</template>
<script>
export default {
  name: 'Message',
  props: ['message', 'mod'],
  components: {
  },
  data: () => ({
  }),
  computed: {
    image () {
      if (this.message.image) {
        return this.message.image;
      }
      return "/icons/user.png";
    }
  },
  watch: {
  },
  methods: {
    deleteMessage: function () {
      this.$emit('deleteMessage', this.message);
    },
    banUser: function () {
      this.$emit('banUser', this.message);
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.chat-message {
  position: relative;
  min-height: 64px;
  flex: 1 1 0 !important;
  display: inline-table;
}
.chat-message-image {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.chat-message-content {
  position: relative;
  display: inline-block;
  width: calc(100% - 80px);
  margin-left: 48px;
  min-height: 100%;
  padding: 0 8px;
}
.chat-message-actions {
  position: absolute;
  display: inline-block;
  right: 0px;
  width: 16px;
  height: 32px;
}
.deleted-chat-message-content {
  color: #808080;
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 100%;
  padding: 0 8px;
}

.message-name {
  color: #808080;
}
.message-text {
  color: white;
  word-break: break-word;
}
</style>
