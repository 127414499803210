<template>
  <v-container class="livestream" fluid>
    <v-row class="livestream-panel">
      <v-col cols="12" md="9" class="livestream-container pa-0">
        <v-responsive v-if="livestream" :aspect-ratio="16/9" :min-height="'100%'" :height="'100%'">
          <iframe v-if="livestream.online" class="viloud" :src="livestream.url" frameborder="0" allow="autoplay" allowfullscreen></iframe>
          <v-img v-else contain content-class="livestream" :height="'100%'" :src="'./backgrounds/disruptv-logo.jpg'"/>
        </v-responsive>
        <div cols="12" class="chat-banner">
        <v-icon color="grey" disabled x-large>mdi-information</v-icon>
         This is a 24/7 stream of free content, click 
        <v-icon color="grey" disabled x-large>mdi-view-list</v-icon>
         in the player to view showtimes
        </div>
      </v-col>
      <v-col cols="12" md="3" class="chat-container pa-0">
        <chat/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import chat from '../components/livestream/chat'
export default {
  name: 'Livestream',
  components: {
    chat
  },
  data: () => ({
  }),
  computed: {
    livestream () {
      return this.$store.getters.getLivestream;
    }
  },
  watch: {
    livestream: function (val) {
      if (val) {
        setTimeout(function () {
          let el = document.getElementsByClassName('vjs-big-play-button')[0];
          if (el) {
            el.click();
          }
        }, 500);
      }
    }
  },
  beforeDestroy () {
    this.$store.dispatch('disconnectChat');
  },
  mounted () {
    this.$store.dispatch('connectChat');
    this.$store.dispatch('getLivestream')
  }
}
</script>
<style lang="scss">
.livestream-panel {
}

.livestream-container {
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 64px);
  overflow-y: scroll;
}

.livestream {
}
.chat-container {
  background-color: #141414;
  height: calc(100vh - 64px);
}

.chat-banner {
  background-color: #0f0f0f;
  font-size: 20px;
  padding: 24px;
  font-style: italic;
  color: gray;
}
.viloud {
  height: 100% !important;
  width: 100% !important;
}

// mobile
.disrupt-mobile .livestream-container {
  height: calc(56vw);
}
.disrupt-mobile .chat-container{
  height: calc(100vh - 56vw - 56px);
}
.disrupt-mobile .chat-banner {
  display: none;
}
</style>
